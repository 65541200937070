table {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  width: 100%;

  th {
    background: $primary-color;
    border: solid 1px $primary-table-border;
    color: $off-white;
    font-size: 12px;
    font-weight: bold;
    padding: .3em .6em;
    text-align: left;
  }

  td {
    background: $white;
    border: 1px solid $light-gray;
    font-size: 12px;
    padding: .6em;

    ol {
      list-style: decimal;
      margin-left: 17px;
    }

    ul {
      list-style: circle;
      margin-left: 17px;
    }

    a {
      color: $black;
    }
  }

  .even {

    td {
      background: $off-white;
    }
  }

  input {
    font-weight: 800;
  }

  b {
    color: $black;
  }
}

.dashboard_left {
  .dataTables_length {
    top: -36px;
  }

  .dataTables_paginate {
    display: block;
    height: 2em;
    margin: .5em auto;
    text-align: center;
    width: 31%;
  }
}

.dataTables_length {
  label {
    color: $darker-gray;
    font-weight: bold;
  }

  select {
    color: $darker-gray;

    option {
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
    }
  }
}

.dataTables_info {
  color: $darker-gray;
  display: block;
  float: left;
  font-size: .8rem;
  font-weight: bold;
  margin: 7px 0;
  width: 26%;
}

.hideColumn {
  display: none;
}
