//Reports file for '/reports' and '/reports/*'
.form-container, .report-form-container {
  background: $white;
  display: inline-block;
  padding: 40px;
  width: 94%;

  .tt-container {
    float: left;
    width: 50%;

    label {
      display: block;
      width: 150px;
    }

    .chosen-container-multi {
      width: 100% !important;
    }
  }

  .form-element-xs {
    width: 25%
  }

  .form-element-sm {
    width: 33%;
  }

  .form-element-md {
    width: 50%;
  }

  .form-element-lg {
    width: 100%;
  }

  .form_divisions {
    label {
      display: block;
      width: calc(100% - 20px);
    }

    input {
      clear: left;
    }
  }

  .form-submit {
    button {
      float: right;
      margin-right: .5em;
    }
  }
}

.form_checkbox {
  padding: .7em 0;

  .form_checkbox_input {
    align-items: center;
    border: 1px solid $light-gray;
    display: flex;
    flex-wrap: wrap;
    height: 150px;
    padding: 10px;
    overflow-y: auto;
    width: calc(100% - 200px);
  }

  input {
    float: left;
  }

  label {
    margin-left: 5px;
  }
}

.form-collapse {
  i {
    color: $dark-gray;
    font-size: 1.5em;
  }

  &:hover {
    i {
      font-size: 2em;
    }
  }
}

.twitter-typeahead {
  .tt-menu {
    display: block;
  }
}

.form_checkbox_input {
  label {
    font-size: 100%;
    font-weight: 400;
  }
}

.advanced-filters {
  background: $white;
  border: 1px solid $light-gray;
  bottom: 0;
  padding: 2.5em;
  position: fixed;
  right: 9em;
  z-index: 1;
}

.advanced-filters-fields-container {
  margin: 1em 0;

  .tt-menu {
    bottom: 100%;
    margin-left: -15em;
    top: auto !important;
  }

  .chosen-container {
    width: 100% !important;
  }
}

.toggle-vis-container {
  background-color: $white;
  border: 1px solid $off-white;
  padding: 1em;
  margin: 10px 0;
}

#user_report_form {
  .form-half {
    width: 47%;
  }

  .ck-editor {
    width: 74%;
  }
}
