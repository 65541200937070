.ui-dialog-titlebar {
  background: $primary-color;
  border-radius: 0;
  border: 0;
  color: $off-white;
  height: 33px;

  span {
    font-size: 1.2em;
  }
}

.ui-dialog {
  background: $white;
  border: 1px solid $black;
  width: 750px !important;

  fieldset {
    input {
      width: 99%;
    }
  }
}

.ui-dialog-title {
  color: $white;
}

.ui-menu {
  background: $white;
  line-height: 1.2em;
  margin-bottom: .2em;
  padding: .2em .5em;
  position: absolute;

  .ui-menu-item {
    &:hover {
      background: 0;
      border: 0;
    }
  }

  a {
    cursor: pointer;
    font-size: 1em;
    padding: .2em .5em;

    &:hover {
      background: 0;
      border: 0;
      color: $black;
      font-size: 1em;
    }
  }
}
.ui-dialog-buttonpane {
  .ui-button {
    float: right;

    &:first-child {
      float: left;
    }
  }
}
