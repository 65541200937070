//AccuQuote foot styles
footer {
  margin-left: 10em;
}

.footerTopWrapper {
  display: block;

  .footerTop {
    border-top: 1px solid $light-gray;
    height: 34px;
    margin: 0 2em;

    .footerLink {
      float: right;

      .backToTop a {
        color: #797979;
        display: block;
        font-size: 1.1em;
        font-weight: bold;
        padding: 0.8em 1.4em;

        @include secondary-to-white-gradient;
      }
    }
    .footerCopyright {
      color: $gray;
      font-size: 1em;
      font-weight: bold;
      margin-top: 1%;
    }
  }
}
.footerSignature {
  margin-bottom: 1%;

  a {
    color: $light-blue;
    font-size: 0.9em;
    font-weight: bold;
    text-decoration: none;
  }
}