//AccuQuote base html styles
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
rem,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
input,
textarea,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  @include reset;

  font-family: $font-default-family;
  font-size: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-header-family;
  margin: 0 0 .5rem 0;
  text-transform: none;
}

h1 {
  color: $darker-gray;
  font-size: $h1-font-size;
  font-weight: bold;
}

h2 {
  color: $dark-gray;
  font-size: $h2-font-size;
  font-weight: bold;
}

h3 {
  color: $gray;
  font-size: $h3-font-size;
  font-weight: bold;
}

h4 {
  color: $darker-gray;
  font-size: $h4-font-size;
  font-weight: bold;
}


h5 {
  color: $dark-gray;
  font-size: $h5-font-size;
  font-weight: bold;
}

h6 {
  color: $gray;
  font-size: $h6-font-size;
  font-weight: bold;
}

label {
  color: $darker-gray;
  font-size: 1rem;
  font-weight: bold;
}

input {
  border: 1px solid #ccc;
  padding: .4rem;
  font-size: 1rem;
}

select {
  padding: .2rem;
}

a {
  color: $dark-gray;
  cursor: pointer;
}

i {
  &:hover {
    color: $black;
  }
}

.hide {
  @include hide;
}

.clearMe {
  clear: both;
}

.clear-me {
  clear: both;
}

.no-link {
  color: $black;
  text-decoration: none;
}

.clear-text-right {
  clear: both;
  float: right;
  text-align: right;
}

strong {
  font-weight: 800;
}
