//Full calendar library styles
/*!
 * FullCalendar v3.10.1
 * Docs & License: https://fullcalendar.io/
 * (c) 2019 Adam Shaw
 */
.fc-event-container {
  .fc-content {
    font-size: 1.1em;
    padding: .15em;
  }
}

.fc-event {
  background-color: $white !important;
  border-color: $gray !important;
  color: $black !important;
}

.fc-view {
  .fc-popover {
    font-size: 1.3em;
    font-weight: 800;
    width: auto;
  }
}
