.col {
  float: left;
}

.col-2 {
  width: 50%;
}

.col-3 {
  width: 33%;
}
