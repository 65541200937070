body {
  @include reset;
  @include font-default;
  background: $off-white;
  font-size: $font-default-size;
  vertical-align: baseline;

  .symfony-content {
    background: $off-white;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    position: relative;

    .search {
      .dataTables_filter {
        display: block;
      }
    }
  }

  .sectionTitle {
    margin: 2rem 0;
  }

  .header-filler {
    left: 0;
    right: 0;
    top: 0;
    height: 46px;
  }
}

@media (max-width: 800px ){
  body {
    font-size: 80%;
  }
}
