//AccuQuote reusable mixins
//Use mixin by @include mixin-name
@mixin font-default {
  font-family: $font-default-family;
  font-size: $font-default-size;
  font-weight: $font-default-weight;
  line-height: $font-default-height;

  @media (min-width: 800px ) {
    Body {
      font-size: 80%;
    }
  }

  @media (min-width: 1024px ) {
    Body {
      font-size: 100%;
    }
  }
}

@mixin white-box-shadow {
  background: $white;
  border-radius: .6em .6em 0 0;
  box-shadow: 0 0 17em $white-shadow;
}

@mixin white-box-shadow-small {
  background: $white;
  border-radius: .4em;
  box-shadow: 0 0 .3em $white-shadow;
  overflow: hidden;
}

@mixin reset {
  border: 0;
  color: inherit;
  font-weight: normal;
  letter-spacing: .5px;
  margin: 0;
  outline: 0;
  padding: 0;
}

@mixin hide {
  display: none !important;
}
