.sales-rep-by-total-combined,
.sales-rep-by-total {
  height: 460px;
  width: 100%;

  #sales-rep-by-total-graph-combined,
  #sales-rep-by-total-graph {
    width: 100%;
  }

  .sales-rep-by-total-row-combined,
  .sales-rep-by-total-row {
    @for $i from 1 through $chart-colors-length {
      &:nth-of-type(#{$chart-colors-length}n+#{$i}) {
        .value {
          color: nth($chart-colors, $i);
        }
      }
    }
  }
}

.total-by-sales-rep-combined,
.total-by-sales-rep {
  flex: 1 0 auto;
}
