.templateForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .formBottom {
    display: inline-block;
    margin-bottom: 3em;
    width: 48%;
  }
}

.template-duplicate {
  position: absolute;
  top: 1.7em;
  right: 14em;
}
