.linked-documents-container {
  background: $white;
  padding: 3em;
}

.document-link-tab {
  display: flex;
  justify-content: space-around;

  .job-folder-half {
    width: 49%;
  }

  input {
    width: 25em;
  }

  .linked-documents-form {
    flex-grow: 1;
    height: 10em;
  }

  .linked-documents {
    border: 1px solid lightgray;
    color: $black;
    display: inline-block;
    font-size: 1.4em;
    float: left;
    flex-grow: 1;
    margin-top: 4em;
    padding: 1em;
    width: 90%;

    ul {
      overflow-y: scroll;
      height: 15em;
      margin: 0;

      li {
        margin: .25em;
      }
    }

    .document-link {
      display: flex;
      justify-content: space-between;

      a {
        flex: 1 0 auto;
      }

      p {
        padding: 0 2em;
      }
    }
  }
}