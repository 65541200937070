figure {
  margin: 0;
}

.text-tiny {
  font-size: .7em;
}

.text-small {
  font-size: .85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 1.8em;
}

.quote_builder {
  .cke_top {
    zoom: .8;
  }

  .cke_editable {
    ol {
      padding: 0 !important;
    }
  }

  .cke_contents {
    height: auto !important;
  }
}

.image_resized {
  img {
    width: inherit;
  }
}

.cke_textarea_inline {
  background: $white;
  border: 1px solid $light-gray;
  color: inherit;
  font-size: .8rem;
  height: 21em;
  overflow-y: scroll;
  padding: 1em;

  ul {
    list-style: inherit;
    margin: 0 0 2em 1em;
  }

  ol {
    list-style: decimal;
    margin: 0 0 2em 1em;
  }
}

.form_checkbox {
  .cke_textarea_inline {
    width: 100%;
  }
}

.ck-editor__editable_inline {
  height: 100%;
}

.ck-editor__top {
  clear: both;
}
