//Top header styles
.main {
  background: $darker-gray;
  left: 0;
  position:fixed;
  top: 0;
  z-index: 12;
  width: 100vw;
}

.headerTop {
  position: absolute;
  right: 0;
  top: -1px;
  width: 100%;
  z-index: 5;
}

.headerLeft {
  display: flex;
  float: left;
  position: relative;
  z-index: 4;
  width: 100%;

  .logo {
    float: left;
    margin: 1px .7em;

    .logo-link {
      background: url(/images/logo.png) 0/136px no-repeat;
      display: block;
      height: 44px;
      text-indent: -99em;
      width: 136px;
    }
  }
}

.headerRight {
  float: left;
  width: 50%;

  .topNav {
    @include gray-background-gradient;
    background: none;
    border-radius: 0 0 0 0;
    display: block;
    float: right;
    margin: .5em 0;
    padding: .5em;
    position: relative;
    z-index: 2;

    .top-menu {
      color: $dark-gray;
      display: block;
      float: left;
      font-size: 1.2em;
      font-weight: 300;
      padding: 0 1.6em;
    }

    .first {
      border-right: 0;

      a {
        color: $secondary-color;
      }
    }

    .last {
      border-left: solid 1px $border-gray-dark;

      a {
        color : $primary-color;
      }
    }
  }
}