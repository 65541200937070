//AccuQuote gradient mixins
@mixin gray-background-gradient {
  background: linear-gradient(to bottom, $gradient-gray-light 0%, $gradient-gray-dark 100%);
}

@mixin gray-hover-background-gradient {
  background: linear-gradient(to bottom, $gradient-hover-gray-light 0%, $gradient-hover-gray-dark 100%);
}

@mixin orange-background-gradient {
  background: linear-gradient(to bottom, $primary-color-pastel 0, $gradient-orange-dark 100%);
  color: $white;
}

@mixin gray-border-background-gradient {
  @include gray-background-gradient;
  border-left: solid 1px $border-gray-dark;
  border-right: solid 1px $border-gray-light;
}

@mixin white-to-secondary-gradient {
  background: linear-gradient(to bottom, $white 0, $secondary-color 100%);
}

@mixin secondary-to-white-gradient {
  background: linear-gradient(to bottom, $secondary-color 0, $white 100%);
}
