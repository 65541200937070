form {
  label {
    color: $dark-gray;
    font-size: .8rem;
    margin-top: 7px;
  }

  option {
    padding: .1em;
  }

  select {
    background: $white;
    border: 1px solid $light-gray;
    padding: .2em;
  }

  input {
    border: 1px solid $light-gray;
    font-size: .8rem;
    padding: .4em;
  }

  textarea {
    border: 1px solid $light-gray;
    font-size: .8rem;
    line-height: 1.4em;
  }
}

.form-row-container {
  display: flex;
  justify-content: space-between;
  margin: .2em;
  text-transform: capitalize;
  width: 100%;
}

.form-collapse {
  clear: both;
  float: right;
  padding: .3em;
  position: absolute;
  right: .5em;
  top: .4em;
}


.form-buttons {
  clear: both;
  height: 3em;
  margin-top: .5em;

  a,
  button {
    float: left;
    margin-left: 2em;
  }
}

.form-file {
  input {
    background: $white;
  }
}

.widget-daterange {
  width: 20em;
}

.form-half {
  float: left;
  width: 48%;
  margin: 1em;
}

.form-container {
  .form-col-2 {
    float: left;
    width: 50%;
  }

  .form-row {
    display: inline-block;
    width: 93%;
  }

  label {
    clear: both;
  }

  input,
  select,
  textarea,
  .cke {
    clear: both;
    float: left;
    width: 100%;
  }
}
