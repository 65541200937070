.job-container {
  margin-left: 98px;
  margin-right: -60px;
  padding: 1em 0;

  .autoSave {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

.jobForm {
  .jobFormItem {
    align-items: center;
    display: flex;
    margin-top: 7px;

    input,
    select {
      flex: 1 0 auto;
      margin-right: 1em;
    }

    .chosen-choices {
      overflow-y: auto;
    }

    .chosen-single {
      max-width: 21em;
    }

    #job_job_name {
      margin-right: -5px;
    }

    #job_bidders,
    #job_general_contractor {
      width: 12%;
      height: 29px;
    }
  }

  .checkBox {
    input {
      flex: 0 0 auto;
    }
  }

  .jobFormTextBox {
    display: block;

    textarea {
      height: 250px;
      padding: 1em;
    }
  }

  .tall {
    margin: -52px 0 -33px;
  }
}

.job-container {
  .job-info {
    .job-info-row {
      font-weight: 800;
      padding: 3px 0;

      label {
        display: inline-block;
        width: 12%;
      }
    }
  }
}

#job_submit {
  border: 1px solid $primary-color;
}


.job_spacer {
  height: 100px;
}

.ui-tabs-panel {
  border: 1px solid $light-gray;
  border-radius: 0;
}

.ui-tabs {
  background: none;

  .tabSectionMenu {
    li {
      background: $light-gray;
      border: 1px solid $gray;
      border-bottom: 0;
      border-right: 0;
      display: block;
      float: left;
      font-size: 1.4em;
      padding: 0;

      &:last-child {
        border-right: 1px solid $gray
      }

      &:hover {
        background: $gray;
      }

      a {
        color: $off-black;
        display: block;
        font-weight: bold;
        margin-right: 0.1em;
        padding: 0.8em 4.6em;
        text-decoration: none;
      }
    }

    .ui-tabs-active {
      background: $white;
    }
  }

  .ui-tabs-nav {
    font-size: .7em;
  }

  @media(min-width: $breakpoint-med) {
    .ui-tabs-nav {
      font-size: .8em;
    }
  }
}

.job_options {
  position: absolute;
  right: 10em;
  top: -6.1em;

  .btn-info,
  .leave_job,
  .join_job {
    margin-right: 10px;
  }
}

.job_save {
  position: absolute;
  right: 1.5em;
  top: -6.2em;
}
