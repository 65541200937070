.quote_line_item_creator {
  font-size: .8em;

  .DTE_Inline {
    display: flex;

    .DTE_Inline_Field {
      flex: 1 0 auto;
      align-self: center;
    }
  }

  .quill-wrapper {
    max-width: 462px;
  }

  .cke_inner {
    display: flex;
    flex-direction: column-reverse;
  }

  .cke_contents {
    height: auto !important;
  }

  .cke_bottom {
    display: none;
  }
}

.quote_line_item_form {
  display: flex;
  flex-direction: column;
}

.quote-line-item-form {
  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .col-1 {
    width: 100%;
  }

  .col-2 {
    width: 42%;
  }

  .col-3 {
    width: 30%;
  }

  .flex {
    clear: both;
    display: flex;
  }

  .dt-button {
    @include reset;
    background: $white-shadow;
    border: 1px solid #ccc;
    display: block;
    float: left;
    font-size: 1.2em;
    margin: 1em 0;
    padding: 0.8em 2em;

    span {
      @include reset;
      font-size: 15px;

      &:hover {
        background: $white-shadow;
      }
    }

    &:hover {
      background: $white-shadow;
    }
  }
}

#yesgroups {
  #add_template_form {
    margin-top: 10px;
  }
}
