.sidebar-menu {
  background: $light-gray;
  height: calc(100% - 74px);
  left: 0;
  position: fixed;
  top: 45px;
  width: 145px;

  .btn-collapse,
  .btn-expand,
  .btn-sidebar {
    background: $light-gray;
    border-radius: 0;
    display: inline-block;
    color: $light-black;
    font-weight: 400;
    height: 19px;
    padding: .75rem;
    text-align: left;
    white-space: nowrap;
    width: calc(100% - 1.6rem);

    i {
      display: flex;
      float: left;
      justify-content: center;
      margin-top: 3px;
      margin-right: 5px;
      width: 17px;
    }

    &:hover {
      background-color: $white-shadow;
    }
  }

  .btn-expand,
  .btn-collapse {
    border-top: 1px solid $gray;
    bottom: 0;
    left: 0;
    padding: .75rem;
    position: fixed;
    width: 119px;
  }

  .active {
    background: $off-white;
    border-left: 4px solid $primary-color;
    width: calc(100% - 1.75rem);

    i {
      margin-left: -4px;
    }
  }
}

.collapsed-sidebar {
  width: 42px;

  .btn-expand,
  .btn-collapse {
    width: 16px;
  }
}

.collapsed-job-container {
  margin-left: -1em;
}
