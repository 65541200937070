//Color variables
$primary-color: #f26331;
$primary-color-pastel: #fba874;
$primary-color-border: #ba4f19;
$primary-table-border: #db824e;
$primary-color-light: #ffc7ad;
$secondary-color: #d6d6d6;
$tertiary-color: #8ec751;

$white: #fff;
$white-shadow: #ddd;
$off-white: #eee;

$black: #000;
$off-black: #222;
$light-black: #333;
$light-gray: #ccc;
$gray: #aaa;
$dark-gray: #666;
$darker-gray: #444;

$light-blue: #bed4eb;

//Gradient variables
$gradient-orange-light: #e78255;
$gradient-orange-dark: #db824e;

$gradient-gray-light: #b4b4b4;
$gradient-gray-dark: #666;

$gradient-hover-gray-light: #969696;
$gradient-hover-gray-dark: #545454;

//Border effect variables
$border-gray-light: #9e9e9e;
$border-gray-dark: #828282;

$red: #ff0000;

$first-graph: #ff8c00;
$second-graph: #00dbff;
$third-graph: #00ff11;
$forth-graph: #ff3400;
$fifth-graph: #fdff02;

$info-background: #5bc0de;
$info-hover: #51adc7;
$info-border: #459bc6;

$hawaii-blue: #88ff7f;

$link: #0000EE;
$visited: #551A8B;

  $chart-colors: $first-graph, $second-graph, $third-graph, $forth-graph, $fifth-graph;
@for $i from 1 through length($chart-colors) {
  $chart-colors: append($chart-colors, lighten(nth($chart-colors, $i), 14));
}
$chart-colors-length: length($chart-colors);
