.calendar {
  .job_container {
    font-size: .8rem;
  }
}

.job_view {
  display: flex;
}

.job_container {
  background: $white;
  box-shadow: none;
  border: 1px solid $gray;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: space-between;
  margin: 0 1% 0 0;
  overflow: hidden;
  width: 19%;
  flex: 1 0 auto;

  .job_header {
    text-align: center;

    .day {
      background: $white;
      border-bottom: solid 2px $light-black;
      color: $light-black;
      font-size: 1.04em;
      margin: 0;
      padding: 1rem;
    }
  }

  section {
    flex: 1 0 auto;
    padding: .8em;
    min-height: 20rem;

    ul {
      margin: 0;

      .job_item {
        background: $white;
        border-bottom: 1px dashed $black;
        border-radius: 0;
        line-height: 1.1em;
        list-style: none;
        margin: 0 0 1em;
        padding: .5em;
      }

      .jobNumber {
        a {
          color: $dark-gray;
          display: block;
          font-size: 1.1em;
          font-weight: 700;
          margin-bottom: .3em;
        }
      }

      .jobDesc {
        font-size: 1.1em;
      }
    }
  }

  .footer {
    background: $white;
    border-top: solid 1px $light-gray;
    padding: .9em 0;
    text-align: center;

    .job_footer_no_more_jobs {
      color: $gray;
    }
  }
}

.calendar {
  box-shadow: none;
  margin: 0 1% 0 0;

  .active-day {
    .job_header {
      h4 {
        background: $primary-color-light;
      }
    }

    .nojobs {
      color: $black;
    }
  }
}

.bgGrey {
  background: $white;
  border: 0;
  padding: 2em;
  margin-bottom: 1.4em;
  border-radius: .5em;
}

@media (max-width: 800px) {
  .calendar,
  .job_container {
    float: none;
    height: auto;
    width: 100%;
  }

  .job_container section {
    height: auto;
    padding: .8em;
    text-align: center;
  }
}
