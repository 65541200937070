.sales-rep-by-percent-combined,
.sales-rep-by-percent {
  height: 400px;
  width: 400px;

  #sales-rep-by-percent-graph-combined,
  #sales-rep-by-percent-graph {
    position: relative;
  }

  .sales-rep-by-percent-row-combined,
  .sales-rep-by-percent-row {
    @for $i from 1 through $chart-colors-length {
      &:nth-of-type(#{$chart-colors-length}n+#{$i}) {
        .value {
          color: nth($chart-colors, $i);
        }
      }
    }
  }
}
