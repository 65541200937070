.manufacturer-by-total-combined,
.manufacturer-by-total {
  height: 460px;
  width: 100%;

  #manufacturer-by-total-graph-combined,
  #manufacturer-by-total-graph {
    width: 100%;
  }

  .manufacturer-by-total-row-combined,
  .manufacturer-by-total-row {
    @for $i from 1 through $chart-colors-length {
      &:nth-of-type(#{$chart-colors-length}n+#{$i}) {
        .value {
          color: nth($chart-colors, $i);
        }
      }
    }
  }
}

.total-by-manufacturer {
  flex: 1 0 auto;
}
