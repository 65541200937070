.main-menu {
  display: flex;
  flex-direction: column;
  width: 100%;

  .mainNav {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-between;
    margin: 0 calc(1rem - 2px);
  }

  .menu-item {
    flex: 1 0 auto;
    width: 13%;

    .mega-menu-active {
      background: $white;
      border: 1px solid $off-white;
      display: block;
      position: absolute;
      right: 15px;
      width: 140px;
    }

    .btn-menu {
      background: $darker-gray;
      border-color: $darker-gray;
      border-radius: 0;
      color: $white;
      margin: 0;
      padding: .75rem;
      text-align: center;

      &:hover {
        background: $dark-gray;
      }
    }

    .btn-menu-active {
      background: $white;
      color: $black;
    }

    .fa {
      color: $white;    
    }
  }

  .menu-item-settings {
    width: 5%;
  }
}

.mega-menu {
  display: none;

  .sub-menu {
    float: none;

    .btn-sub-menu {
      background: $white;
      border: 0;
      color: $black;
      padding: .75rem;
      text-align: left;
      width: calc(100% - 1.5rem);
    }

    .btn-menu,
    .btn-menu-active {
      background: $white;
      border-radius: 0;
      color: $black;
      font-weight: normal;
      padding: .5em;
      width: 100%;

      &:active,
      &:hover {
        background: $light-gray;
      }
    }
  }
}

.main {
  background: $darker-gray;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 12;
}
