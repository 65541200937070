.card {
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 5px;
  display: flex;
  font-size: 110%;
  flex-direction: column;
  margin: 1em;
  width: 290px;

  .card-block {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: space-between;
    padding: 2.5em;

    .card-title {
      margin-bottom: 1em;
    }

    .card-text {
      line-height: 105%;
      font-size: 1.2em;
      margin-bottom: 1.5em;
    }

    .card-buttons {
      display: flex;
      flex-direction: column;

      .btn {
        margin-top: 3px;
      }
    }
  }
}

.card-flex-container {
  display: flex;
  flex-wrap: wrap;
}
