//AccuQuote DataTable library styling - separate from _tables.scss
.dt-buttons {
  .dt-button {
    @extend .btn;
    @extend .btn-primary;
    margin: 5px 19px 5px 0;
  }
}

.dataTable tbody th.focus,
.dataTable tbody td.focus {
  box-shadow: inset 0 0 1px 2px #3366ff;
}

.dtk-focus-alt table.dataTable tbody th.focus,
.dtk-focus-alt table.dataTable tbody td.focus {
  box-shadow: inset 0 0 1px 2px #ff8b33;
}


.dt-autofill-handle {
  position: absolute;
  height: 8px;
  width: 8px;
  z-index: 102;
  box-sizing: border-box;
  background: #3366ff;
  cursor: pointer;
}

.dtk-focus-alt .dt-autofill-handle {
  background: #ff8b33;
}

.dt-autofill-select {
  position: absolute;
  z-index: 1001;
  background-color: #4989de;
  background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 255, 255, 0.5) 5px, rgba(255, 255, 255, 0.5) 10px);
}
.dt-autofill-select.top, .dt-autofill-select.bottom {
  height: 3px;
  margin-top: -1px;
}
.dt-autofill-select.left, .dt-autofill-select.right {
  width: 3px;
  margin-left: -1px;
}

.dt-autofill-list {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 500px;
  margin-left: -250px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0 5px #555;
  border: 2px solid #444;
  z-index: 11;
  box-sizing: border-box;
  padding: 1.5em 2em;
}
.dt-autofill-list ul {
  display: table;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.dt-autofill-list ul li {
  display: table-row;
}
.dt-autofill-list ul li:last-child .dt-autofill-question, .dt-autofill-list ul li:last-child .dt-autofill-button {
  border-bottom: none;
}
.dt-autofill-list ul li:hover {
  background-color: #f6f6f6;
}
.dt-autofill-list .dt-autofill-question {
  display: table-cell;
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc;
}
.dt-autofill-list .dt-autofill-question input[type=number] {
  padding: 6px;
  width: 30px;
  margin: -2px 0;
}
.dt-autofill-list .dt-autofill-button {
  display: table-cell;
  padding: 0.5em 0;
  border-bottom: 1px solid #ccc;
}
.dt-autofill-list .dt-autofill-button button {
  color: white;
  margin: 0;
  padding: 6px 12px;
  text-align: center;
  border: 1px solid #2e6da4;
  background-color: #337ab7;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
}

.dt-autofill-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  background: radial-gradient(ellipse farthest-corner at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
  z-index: 10;
}

.dataTables_wrapper {
  .top {
    display: flex;
    justify-content: space-between;
  }

  .dataTables_filter {
    display: none;
  }

  .row {
    .DTE_Label {
      display: none;
    }
  }

  input {
    margin: -5px -5px -5px 0;
    padding: 0;
    width: 100%;
  }

  textarea {
    width: 95%;
  }

  .editor {
    line-height: 1;
  }

  .input-number {
    margin-top: 1px;
    width: 65%;
  }

  .control-buttons {
    display: flex;
    height: 21px;
    flex-direction: column;
    float: right;
    margin-top: -7px;

    .int-control {
      border: 0;
      float: right;
      height: 13px;
      overflow: visible;
      padding: 0;
    }
  }
}

.DTE_Header_Content {
  display: none;
}

.DTE_Action_Create,
.DTE_Action_Edit {
  background: rgba(238, 238, 238, 0.84);
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  left: 0;
  padding: 0 10em;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 13;

  @media screen and (min-width: $breakpoint-large) {
    padding: 0 30em;
  }

  .DTE_Body {
    background: $white;
    padding: 1em;
    border: 2px solid $off-black;
    border-top: 0;
  }

  .DTE_Footer {
    background: $white;
    padding: 1em;
    border: 2px solid $off-black;
    border-bottom: 0;
  }

  .DTE_Label {
    color: $black;
    float: left;
    margin: 0;
  }

  .DTE_Field {
    float: left;
    padding: .2em 1em;
    width: 45%;
  }

  .DTE_Field_Type_ckeditor,
  .DTE_Field_Type_quill {
    padding: .2em;
    width: 100%;

    .DTE_Label {
      float: none;
    }
  }

  .DTE_Field_Input {
    input {
      float: right;
      width: 40%;

      &:disabled {
        background: $light-gray;
      }
    }

    select {
      float: right;
      width: 41%;
    }
  }

  .quill-wrapper {
    height: 11.5em;
    width: 92%;

    .editor {
      height: 67%;
    }
  }

  .DTE_Form_Buttons {
    button {
      @extend .btn-danger;
      margin: .5em;
    }

    .btn-primary {
      @extend .btn-primary;
      color: $black;

      &:hover {
        border: 1px solid $gray;
      }
    }

    .job-row-delete {
      float: right;
    }
  }

  .quote_builder_form_legend {
    width: 100%;

    div {
      float: left;
    }
  }

  .quote_builder_form_notice {
    color: $red;
    float: left;
  }
}

.DTE_Bubble {
  background: $white;
  border: 1px solid $black;
  left: 20rem !important;
  padding: .5em;
  position: absolute;
  width: calc(100% - 26rem);
}

.DTE_Field_Error {
  color: $red;
  float: right;

  .multi-error {
    margin: 10px 0 0;
  }
}

