.job-log {
  border: 1px solid $light-gray;
  height: 87%;
  max-height: 352px;
  overflow-y: scroll;
  padding: 1em;

  .job-log-entry {
    border-bottom: 1px solid $light-gray;
    font-size: 1.2em;
    padding: .5em;

    &:nth-child(even) {
      background-color: $off-white;
    }
  }
}
