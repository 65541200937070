.quotegroup-attr {
  background: $white;
  border: 1px solid $off-white;
  padding: 2em;

  .button-a {
    display: inline-block;
    margin-left: 10px;
    padding: 9.5px 16px;
  }
}

#quote_tab {
  background: $white;
  margin-bottom: 1.4em;

  .buttons {
    float: right;
    margin: 1em 0;
  }
}

.export-job {
  display: inline-block;
}

.autoSave {
  i {
    margin-right: 3px;
  }
}

.saved-notification {
  background: $primary-color-light;
  border: 1px solid $primary-color-pastel;
  display: none;
  font-weight: 800;
  position: fixed;
  padding: 10px;
  right: 0;
  z-index: 15;
}

.basis-of-design {
  float: left;
}

.quote-group-dialog {
  width: 100%;

  .form-popup {
    padding: 3em;
  }
}

.new-quote-group-title {
  float: left;
}

.save-quote-group {
  float: right;
}

.quote_handle {
  float: left;
  margin-left: -60px;
  padding: .5em;
  z-index: 10;
}

.quotegroup-attr {
  margin-bottom: 50px;

  .quote-group-button-container {
    float: left;
  }

  .myGridTitle {
    float: left;

    .myGridTitleSpan {
      color: $black;
    }

    input {
      margin-left: 10px;
    }
  }

  .quote-group-settings {
    float: right;

    .settings-icon {
      cursor: pointer;
      float: left;
      height: 25px;
      width: 25px;
    }

    i {
      color: $black;
    }

    .settings-container {
      background-color: $white;
      border: 1px solid $white-shadow;
      display: none;
      position: absolute;
      right: 7px;
      width: 8em;
      z-index: 1;

      .quote-group-button-container {
        clear: both;
        display: inline-block;
        float: left;
        width: 100%;

        .menu-link {
          cursor: pointer;
          padding: 1em;
          width: calc(100% - 1.5em);
        }

        a {
          @include reset;

          color: $black;
          display: block;
          text-decoration: none;
        }

        &:hover {
          background-color: $white-shadow;
        }
      }
    }
  }

  .button-container {
    float: right;

    button {
      margin-right: 6px;
    }
  }

  .quote-group-bod {
    clear: both;
    float: left;
  }

  .summary {
    width: 99.9%;

    th {
      background: $primary-color-pastel;
      border: 1px solid $primary-table-border;
      border-top: none;
      color: $black;
    }
  }
}

.new-quote-group-container {
  .quotegroup-attr {
    background: $off-white;
  }
}

#quote_tab {
  padding: 2em;
}

.quote_builder {
  .group-header-cell {
    background: $light-gray;
    border: 1px solid $border-gray-light;
    color: $off-black;
  }

  .group-cell {
    background: $primary-color-pastel;
    border: 1px solid $primary-table-border;
    color: $black;
  }
}

.quote-group-info {
  clear: both;
  display: flex;
  float: left;
}
