.contactForm {
  .formTop {
    display: flex;
    margin-bottom: 1.5em;

    label {
      margin-left: 1%;
    }
  }

  .formBottom {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    margin: 1em;

    .contactFormItem {
      min-height: 40px;

      label {
        float: left;
        width: 40%;
      }

      input {
        width: 40%;
      }

      select {
        width: 52%;
      }
    }

    .btn-primary {
      margin-bottom: 1em;
    }
  }

  .manage-csp {
    margin-bottom: 1em;

    a {
      width: 100%;
    }
  }

  .twoCol {
    float: left;
    width: 33%;
  }

  #dialog-salespeople {
    td,
    th {
      font-size: .9em;
      font-weight: normal;
    }
  }
}
