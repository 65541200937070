.container {
  .section-info {
    font-size: 1.4em;
    font-weight: 500;
    line-height: 1.2em;
    margin: 0 0 1em;

    .item-list {
      margin: 0 2em;
    }
  }
}