.graph-container {
  clear: both;
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
}

.graphs {
  .graph-tab-container {
    display: flex;
    float: right;
    font-size: 1em;
    margin-bottom: 1em;
  }

  .graph {
    float: left;
    height: 400px;
  }
}

#open,
#combined {
  clear: both;
  height: 95em;
  justify-content: space-around;
  width: 100%;
}

.manufacturers,
.sales-rep {
  display: flex;
  padding: 0 5em;
}
