.super-group-form {
  background-color: $off-white;
  border: 0 solid $white-shadow;
  border-top-width: 1px;
  padding: 2em;

  .form-row {
    float: left;
    margin: 1rem;
    width: 45%;
  }

  .custom-switch {
    margin-top: 2rem;
  }

  .form-row-table {
    clear: both;
  }

  .chosen-container {
    width: 100% !important;
  }

  .cke_textarea_inline {
    height: 5em;
  }

  .ck-content {
    height: 4rem;
  }
}

.super-group {
  border: 2px solid $primary-color-pastel;
  clear: both;
  margin-bottom: 50px;

  .quote_handle {
    margin-left: -45px;
  }

  .super-group-header {
    display: flex;
    margin-bottom: 10px;
    padding: 2em 2em 0;

    .super-group-name {
      margin-top: 4px;
    }

    .super-group-button-container {
      margin-left: 15px;
    }
  }

  .super-group-label {
    font-size: 18px;
    padding: .5em 1em;

    .super-group-price {
      float: right;
    }

    th {
      background-color: $primary-color-pastel;
      color: $black;
    }
  }

  .quotegroup-attr {
    margin-bottom: 0;
    padding-bottom: 1em;
  }
}
