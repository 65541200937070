.DTE_Form_Content {
  .DTE_Field {
    padding: .5em 1em;
  }

  input {
    font-size: 1em;
    font-weight: 800;
    padding: 3px;
  }

  #DTE_Field_feature {
    margin-top: -5px;
    padding: 0;
    width: 100%;
  }

  .DTE_Field_Name_equipment {
    clear: both;
  }

  .DTE_Field_Name_model {
    clear: both;
    margin-bottom: 15px;
  }

  .DTE_Field_Name_quantity {
    margin-top: 15px;
  }

  .DTE_Field_Name_list_price_each {
    clear: both;
  }

  .DTE_Field_Name_buy_multiplier {
    clear: both;
  }

  .DTE_Field_Name_margin {
    clear: both;
  }

  .DTE_Field_Name_sell_price {
    margin-bottom: 15px;
  }

  .DTE_Field_Name_weight {
    clear: both;
  }

  .DTE_Field_Name_freight_multiplier {
    clear: both;
  }

  .DTE_Field_Name_freight {
    margin-top: -1px;
  }

  .DTE_Field_Name_base_subtotal {
    clear: both;
    margin-top: 20px;
  }

  .DTE_Field_Name_optionprice {
    margin: 16px 0 20px;
  }
}