.template-matches {
  font-size: 1.1em;
  margin-bottom: 1em;

  a {
    color: $link;

    :visited {
      color: $visited;
    }
  }
}

.updater-continue {
  display: flex;
  justify-content: space-around;
  margin: auto;
  padding: 2em;
  width: 25%;
}
